import React from 'react';

// Bootstrap
import {Container} from 'react-bootstrap';

// Less
import './Banner.less';

const Banner = ({
  bannerOverlay,
  bannerOpacity,
  children,
  text,
  className,
  contactDetails,
}) => {
  const classes = `banner ${className}`;

  return (
    <div className={classes}>
      {bannerOverlay && (
        <div className="banner__overlay" style={bannerOverlay} />
      )}
      <div className="banner__gradient" style={bannerOpacity} />
      <div className="banner__content">
        <Container fluid className="banner__container">
          <h1>{children}</h1>
          {text && <p>{text}</p>}
          {contactDetails && (
            <div className="banner__contactDetails">
              <p>{contactDetails.name}</p>
              <p>{contactDetails.position}</p>
              <p>
                <a href={`mailto:${contactDetails.email}`}>
                  {contactDetails.email}
                </a>
              </p>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default Banner;
