import React from 'react';

// Less
import './JobDetails.less';

// Bootstrap
import Container from 'react-bootstrap/Container';

// Layout
import Layout from '../../layout/Layout';

// Components
import Banner from '../../../components/common/banner/Banner';
import TextWidget from '../../../components/common/text-widget/TextWidget';

// Assets
import bannerImage from '../../../../static/assets/banner/banner-careers-ro.png';

const parse = require('html-react-parser');

const JobDetails = ({pageContext: {job, moreAboutEnsemble}}) => {
  const bannerOpacity = {
    opacity: '0.75',
  };

  const bannerOverlay = {
    backgroundImage: `url(${bannerImage})`,
  };

  const qualifications =
    job.qualifications.qualificationsList.length > 0 &&
    job.qualifications.qualificationsList.map((qualification, index) => (
      <li key={index}>
        <p>{qualification}</p>
      </li>
    ));

  const additionalInformationContent =
    job.additionalInformation.content.length > 0 &&
    job.additionalInformation.content.map((item, index) => (
      <div key={index}>
        <p>
          <b>{item.heading}</b>
        </p>
        {item?.subHeading && parse(`<p>${item.subHeading}</p>`)}
        <ul className="job-details__information-list">
          {item.informationList.map((listItem, listElementIndex) => (
            <li key={listElementIndex}>
              <p>{listItem}</p>
            </li>
          ))}
        </ul>
      </div>
    ));

  return (
    <Layout>
      <Banner
        bannerOpacity={bannerOpacity}
        bannerOverlay={bannerOverlay}
        className="bannerSecondary"
      >
        {job.position}
      </Banner>
      <Container className="job-details">
        <p className="job-details__location">
          <b>Location:&nbsp;</b>
          {job.location}
        </p>
        <p className="job-details__job-description">{job.description}</p>
        <p className="job-details__qualifications-heading">
          <b>{job.qualifications.heading}</b>
        </p>
        <ul className="job-details__qualifications">{qualifications}</ul>
        {job.additionalInformation && (
          <div className="job-details__additional-information">
            {job.additionalInformation.firstParagraph && (
              <p>{job.additionalInformation.firstParagraph}</p>
            )}
            {additionalInformationContent && additionalInformationContent}
            {job.additionalInformation.lastParagraph && (
              <p>{job.additionalInformation.lastParagraph}</p>
            )}
          </div>
        )}
        <TextWidget
          data={moreAboutEnsemble}
          className="text-widget--jobDetails"
        />
        <p className="job-details__contact">
          {job.contact.text}
          &nbsp;
          <a href={`mailto:${job.contact.mail}`}>{job.contact.mail}</a>
        </p>
      </Container>
    </Layout>
  );
};

export default JobDetails;
